<template>
    <section v-bind:id="NameLocation"  class="board-directors section" data-sectionThemeDark="true">
        <main class="directors-mobile">
            <h2 class="section-heading">Board of Directors</h2>
            <div class="director-grid-item director-right">
                <div class="director-profile">
                    <img src="../../assets/imgs/board-of-directors/wimpie-150px.png" >
                </div>
                <div class="director-title ">
                    <p class="director-text">
                        Wimpie van der Merwe<br>
                        Group CEO and Executive director
                    </p>
                </div>
            </div>
            <div class="director-grid-item director-left">
                <div class="director-profile">
                    <img src="../../assets/imgs/board-of-directors/yumna-2-150px.png" >
                </div>
                <div class="director-title">
                    <p class="director-text">
                        Yumna Varachia<br>
                        Executive Claims Costing and HR Director
                    </p>
                </div>
            </div>
            <SocialIconsMobile />
        </main>

        <main class="directors-full">
            <div class="director-grid-container">
                <h2 class="section-heading">Board of Directors</h2>
                <div class="director-item --wimpie">
                    <img src="../../assets/imgs/board-of-directors/wimpie.png" class="" data-effect="slideup">
                    <div class="director-title-wimpie">
                        <p class="director-text">Wimpie van der Merwe<br>
                            <span>Group CEO and Executive director</span>
                        </p>
                    </div>
                </div>
                <div class="director-item --yumna">
                    <img src="../../assets/imgs/board-of-directors/yumna-2.png" class="" data-effect="slideup">
                    <div class="director-title-yumna">
                        <p class="director-text">Yumna Varachia<br>
                        <span>Executive Claims Costing and HR Director</span>
                        </p>
                    </div>
                </div>
            </div>
        </main>

    </section>
</template>
<script>
import SocialIconsMobile from '@/components/ui/SocialIconsMobile'

export default {
  name: 'BoardOfDirectors',
  props: ['NameLocation'],
  components: { SocialIconsMobile },
  data () {
    return {

      scrolled: false,
      currentSectionID: 0,
      scrollBarPos: 0,
      scrollBarPosYoffset: 0,
      sectionHeight: 0,
      sectionEndPos: 0,
      socialIconsWebThemeDark: true,
      currentMenuItem: ''
    }
  },
  methods: {
    handleScroll2 () {
      // function scrollCheck() {
      //        scrolling = true;
      //    }
      setInterval(function () {
        if (scrolling) {
          scrolling = false
        }
      }, 250)
      this.scrollBarPos = window.scrollY
      let scrolling = false
      const BoardOfDirectors = document.querySelector('#BoardOfDirectors')
      if (BoardOfDirectors) {
        const BoardOfDirectorsStartPos = BoardOfDirectors.offsetTop
        // let BoardOfDirectorsEndPos = BoardOfDirectorsStartPos + BoardOfDirectors.offsetHeight;

        if ((BoardOfDirectorsStartPos) < this.scrollBarPos) {
          // console.log('THIS scrollBarPos at : '+ this.scrollBarPos);

          this.activeEffects(BoardOfDirectorsStartPos)
        }
      }
    },
    activeEffects () {
      const target = document.querySelectorAll('.fx')

      let index = 0; const length = target.length
      for (index; index < length; index++) {
        // var pos = window.pageYOffset * target[index].dataset.rate;
        if (target[index].dataset.effect === 'slideup') {
          const posXscrolled = window.pageYOffset
          // console.log(posXscrolled);
          // target[index].style.transition.opacity =  '2';
          // if(posXscrolled > BoardOfDirectorsStartPos){
          if (posXscrolled > 1900) {
            target[index].style.opacity = '1'
            // target[index].style.marginBottom = '0px';
            // console.log('win');

            // }else if(BoardOfDirectorsStartPos > posXscrolled){
          } else if (posXscrolled < 1899) {
            // console.log('drop');
            target[index].style.opacity = '0'
            // target[index].style.marginBottom =  '-1430px';
          }
        }
      }
    }
  },
  created: function () {
    let scrolling = false
    // attached listner to scrollActive, make scrolling true
    window.addEventListener('scroll', scrollActive)
    function scrollActive () {
      scrolling = true
    }

    /*   on created. this continuesly goes off every 0.35sec, checks if the page is scrolling.
        if it is, trigger the handleScroll method.         */
    setInterval(() => {
      if (scrolling) {
        scrolling = false
        this.handleScroll2()
      }
    }, 350)
  }
}
</script>
<style>

</style>
