var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-container"},[_c('nav',{staticClass:"nav-bar",class:[{'active':_vm.activated},{'nav-bar--light':this.sectionThemeDark},{' nav-bar--dark':!this.sectionThemeDark}],attrs:{"id":"navbar"}},[_c('div',{staticClass:"menu icon",class:[{' icon-back-button':_vm.activated},{' icon-burger-menu':!_vm.activated}],on:{"click":_vm.markActive}}),_c('ul',{staticClass:"nav-items"},_vm._l((_vm.MenuItems),function(MenuItem){return _c('li',{key:MenuItem.linkID},[_c('a',{class:{'active':MenuItem.isCurrentSection},on:{"click":function($event){return _vm.goto(MenuItem.linkNameLocation)}}},[_vm._v(_vm._s(MenuItem.linkName))])])}),0)]),_c('div',{staticClass:"close-menu",on:{"click":_vm.closeMenu}}),_c('div',{staticClass:"social-icons-web",attrs:{"id":"socialIconsWeb"}},[_c('ul',{staticClass:"social-items",class:[
        {'light':this.socialIconsWebThemeDark},
        {'dark':!this.socialIconsWebThemeDark}
        ]},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"facebook",attrs:{"href":"https://www.facebook.com/GlobalChoicesZA/","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"twitter",attrs:{"href":"https://twitter.com/GlobalChoicesZA","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"linkedin",attrs:{"href":"https://www.linkedin.com/company/global-choices-lifestyle/?originalSubdomain=za","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"instagram",attrs:{"href":"https://www.instagram.com/globalchoicesza/","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"youtube",attrs:{"href":"https://www.youtube.com/channel/UCbCW_LVHs3zhXqtY6yRETBQ","target":"_blank","rel":"noopener noreferrer"}})])
}]

export { render, staticRenderFns }