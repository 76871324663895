import { render, staticRenderFns } from "./ResponsiveBurgerMenu.vue?vue&type=template&id=9c833a8a&scoped=true"
import script from "./ResponsiveBurgerMenu.vue?vue&type=script&scoped=true&lang=js"
export * from "./ResponsiveBurgerMenu.vue?vue&type=script&scoped=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c833a8a",
  null
  
)

export default component.exports