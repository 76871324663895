<template>
  <v-app id="app">
    <div class="main-wrapper">
        <router-view />
    </div>
  </v-app>
</template>

<script>
import 'hooper/dist/hooper.css'
export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
    @import '@/scss/styles.scss';
</style>
