<template>
        <section  class="keeping-you-safe section" id="KeepingYouSafe" data-sectionThemeDark="false">
            <h2 class="section-heading --blue">Keeping you Safe</h2>
            <p class="lead-text">We believe the experiences an insurer delivers will have the biggest impact on attracting and retaining policyholders in the future. Our 24-hour emergency multiple platform solutions combined with the below listed products give you and your clients the competitive edge to stay ahead.</p>
            <div class="keeping-content--container" >

                <!--
                    takes the literall direction class from data string. but we want to alternate it incase things change and the class isnt updated.

                    <div class="product"
                    v-bind:class="kysItem.directionClass"
                    v-bind:key="KeepingYouSafeItems.id"
                    v-for="kysItem in sortedArray"
                > -->

                <!-- auto classes the data regardless of pre-set class. -->
                <div class="product"
                    v-for="(kysItem, index)  in sortedArray"
                    v-bind:class="{'product--left': index % 2 === 0, 'product--right': index % 2 !== 0 }"
                    v-bind:key="kysItem.id"
                >
                    <div class="product--border-holder">
                        <div class="product--grey-line"></div>
                    </div>
                    <div class="prod-img">
                        <img v-bind:src="require('../../assets/imgs/keeping-you-safe/' + kysItem.image)" />
                    </div>
                    <div class="prod-info">
                        <div class="info-container">
                            <div class="icon" v-bind:class="kysItem.icon"></div>
                            <h3 class="product-heading" v-html="kysItem.heading"></h3>
                            <p class="content-text"  v-html="kysItem.contentText"></p>
                            <p class="content-text"><a v-bind:href="kysItem.url" target="_blank" rel="noopener noreferrer" v-html="kysItem.siteName"></a></p>
                            <!-- <a v-bind:href="kysItem.link" target="_blank" rel="noopener noreferrer" v-html="kysItem.link"></a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="end-container">
                <!--<a href="#keepingYouSafe"  class="">
                    <div class="btn secondary-btn">More Info</div>
                </a>-->
                <div class="scroller--element">
                    <scroller v-bind="ScrollerConfig" />
                </div>
            </div>
        </section>
</template>
<script>
import Scroller from '@/components/ui/Scroller'

export default {
  name: 'ServiceList',
  components: { Scroller },
  props: ['KeepingYouSafeItems'],
  computed: {
    // the sortedArray key word gets used in the v-for loop instead of KeepingYouSafeItems

    sortedArray: function () {
      function compare (a, b) {
        if (a.sortOrder < b.sortOrder) { return -1 }
        if (a.sortOrder > b.sortOrder) { return 1 }
        return 0
      }

      return [...this.KeepingYouSafeItems].sort(compare)
    }
  },
  data () {
    return {
      ScrollerConfig: {
        color: '#c6c6c6',
        animated: true,
        activeState: true,
        scrollTxt: false
      }
    }
  }
}
</script>
<style></style>
