import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Head from 'vue-head'
Vue.use(VueRouter)
Vue.use(Head, {
  complement: 'Global Choices'
})
const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/digitalpath',
    name: 'digitalpath',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/DigitalPath.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/disabilityassist',
    name: 'disabilityassist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/DisabilityAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/claimcentral',
    name: 'claimcentral',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/ClaimCentral.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/CallCenterAndGlobalStars',
    name: 'CallCenterAndGlobalStars',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/CallCenterAndGlobalStars.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/OurApps',
    name: 'OurApps',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/OurApps.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensStepAssist',
    name: 'AppScreensStepAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensStepAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensCommriskAssist',
    name: 'AppScreensCommriskAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensCommriskAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensOrangeAssist',
    name: 'AppScreensOrangeAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensOrangeAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensIntegritasAssist',
    name: 'AppScreensIntegritasAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensIntegritasAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensSuperValueAssist',
    name: 'AppScreensSuperValueAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensSuperValueAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensTWKAssist',
    name: 'AppScreensTWKAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensTWKAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/AppScreensFrontlineAssist',
    name: 'AppScreensFrontlineAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/AppScreensFrontlineAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  {
    path: '/24hMotorAssist',
    name: '24hMotorAssist',
    component: () =>
      import(/* webpackChunkName: "client-chunk-login" */ '@/views/24hMotorAssist.vue'),
    meta: {
      authNotRequired: true
    }
  },
  { path: '*', redirect: '/home' }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  routes
})

export default router
