<template>
     <div class="social-icons-mobile" >
        <ul class="social-items" v-bind:class="[
            {'dark':DarkIconsTrue},
            {'light':!DarkIconsTrue}
            ]">
            <li><a href="https://www.facebook.com/GlobalChoicesZA/" class="facebook" target="_blank" rel="noopener noreferrer"></a></li>
            <li><a href="https://twitter.com/GlobalChoicesZA" class="twitter"  target="_blank" rel="noopener noreferrer"></a></li>
            <li><a href="https://www.linkedin.com/company/global-choices-lifestyle/?originalSubdomain=za" class="linkedin"  target="_blank" rel="noopener noreferrer"></a></li>
            <li><a href="https://www.instagram.com/globalchoicesza/" class="instagram"  target="_blank" rel="noopener noreferrer"></a></li>
            <li><a href="https://www.youtube.com/channel/UCbCW_LVHs3zhXqtY6yRETBQ" class="youtube"  target="_blank" rel="noopener noreferrer"></a></li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SocialIconsMobile',
  props: ['DarkIconsTrue']
}
</script>
