<template>
    <section v-bind:id="NameLocation" class="apps-callcenter section" data-sectionThemeDark="true">
        <main class="departments">
            <article class="section-item">
                <div class="bkg-c bkg-c--lb"></div>
                <div class="mobile-icon icon icon-our-mobile-apps --prim"></div>
                <h2 class="section-heading">Mobile Apps</h2>
                <p class="apps--text --prim">Following a total experience (TX) strategy, we have created the best mobile app solution for 24-hour emergency assistant services.</p>
                <img src="../../assets/imgs/mobile-apps-visual.png" class="app-custom-space">
                <router-link to="/OurApps" class="btn apps--btn"> Our Apps</router-link>
                <!-- <a  href="/OurApps#Home" class="btn apps--btn"> Our Apps</a> -->
            </article>

            <article class="section-item">
                <div class="bkg-c bkg-c--db"></div>
                <div class="mobile-icon icon icon-our-call-centre --prim"></div>
                <h2 class="section-heading">Our Call Centre and Global Stars</h2>
                <p class="callcenter--text --prim">Let Global Choices add value to your business and breathe new life into  your customer experience with our innovative call centre support. </p>
                <img src="../../assets/imgs/call-centre-and-global-stars-visual.png"  >
                <router-link to="/CallCenterAndGlobalStars#Home" class="btn callcenter--btn"> Our Global Stars </router-link>
                <!-- <a  href="/CallCenterAndGlobalStars#Home" class="btn callcenter--btn"> Our Global Stars </a> -->
                <SocialIconsMobile />
            </article>
        </main>
    </section>
</template>
<script>
import SocialIconsMobile from '@/components/ui/SocialIconsMobile'

export default {
  name: 'OurAppsOurCallCenter',
  props: ['NameLocation'],
  components: { SocialIconsMobile }
}
</script>
<style>

</style>
