<template>
  <div class="home-view">
    <responsive-burger-menu :menu-items="MenuItems" />
    <landing-intro-v2 v-bind:LandingIntroData="LandingIntroData" />
    <executive-summary v-bind="ExecutiveSummaryData" />
    <group-of-companies v-bind="GroupOfCompaniesData" />
    <board-of-directors v-bind="BoardOfDirectorsData" />
    <service-list :keeping-you-safe-items="KeepingYouSafeItems" slot-scope=""/>
    <our-apps-our-call-center v-bind="OurMobileAppsData" />
    <contact-us v-bind="ContactUsData" />
    <FooterLayout />
  </div>
</template>
<script>
import ResponsiveBurgerMenu from '@/components/ui/ResponsiveBurgerMenu.vue'
// import LandingIntro from '@/components/layout/LandingIntro'
// import CovidBanner from '@/components/layout/CovidBanner.vue'
import LandingIntroV2 from '@/components/layout/LandingIntroV2.vue'
import ExecutiveSummary from '@/components/layout/ExecutiveSummary.vue'
import BoardOfDirectors from '@/components/layout/BoardOfDirectors.vue'
import ServiceList from '@/components/layout/ServiceList.vue'
import GroupOfCompanies from '@/components/layout/GroupOfCompanies.vue'
import OurAppsOurCallCenter from '@/components/layout/OurAppsOurCallCenter.vue'
import ContactUs from '@/components/layout/ContactUs.vue'
import FooterLayout from '@/components/layout/Footer.vue'

export default {
  components: {
    ResponsiveBurgerMenu,
    // CovidBanner,
    LandingIntroV2,
    ExecutiveSummary,
    BoardOfDirectors,
    ServiceList,
    GroupOfCompanies,
    OurAppsOurCallCenter,
    ContactUs,
    FooterLayout
  },
  data () {
    return {
      MenuItems: [
        {
          linkID: 1,
          linkName: 'Home',
          linkNameLocation: 'Home',
          isCurrentSection: true,
          linkActive: true
        },
        {
          linkID: 2,
          linkName: 'About Us',
          linkNameLocation: 'AboutUs',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 3,
          linkName: 'Group Of Companies',
          linkNameLocation: 'GroupOfCompanies',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 4,
          linkName: 'Board Of Directors',
          linkNameLocation: 'BoardOfDirectors',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 5,
          linkName: 'Keeping You Safe',
          linkNameLocation: 'KeepingYouSafe',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 6,
          linkName: 'Our Mobile Apps',
          linkNameLocation: 'OurMobileApps',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 7,
          linkName: 'Our Call Centre',
          linkNameLocation: 'OurMobileApps',
          isCurrentSection: false,
          linkActive: true
        },
        {
          linkID: 8,
          linkName: 'Contact Us',
          linkNameLocation: 'ContactUs',
          isCurrentSection: false,
          linkActive: true
        }
      ],
      LandingIntroData: {
        NameLocation: 'Home',
        BackgroundClass: '--home-banner',
        HeadingText:
          'Adding to Life by<br> focussing on what<br> matters most.',
        Btn: '#KeepingYouSafe',
        BtnState: true,
        ShowVideo: true
      },
      ExecutiveSummaryData: {
        NameLocation: 'AboutUs',
        Title: 'Global Choices is a 24 hour assistance and value added claim solutions company',
        TitleClass: '--blue',
        LeadText:
          'We create and maintain partnerships by adding to everyday life with our innovative digital solutions.',
        Content:
          'Global Choices Lifestyle (Pty) Ltd has been operating in the 24-hour emergency and value-added products industry for the past 22 years, providing bespoke emergency, value-added and digital solutions to large and small databases of insurance companies across South Africa. Currently we manage around 3 million insurance covered members with a demographic breakdown of varying age groups and databases. Global Choices is always on call, on demand for our clients and their policyholders to ensure that their emergency risk events are managed efficiently. We become a valuable extension our client’s business with the right team, suppliers, and digital solutions to case manage any incident and offer solutions to take care of your customers.<br><br>Customers are not just looking to buy "insurance" in order to protect themselves, their families, and their lifestyles from unexpected financial disruptions. They are actively seeking out innovative insurance products that can not only insulate them from risk but also accommodate the ever-changing needs as customers. We assist insurers to prioritize customer experience when creating these new offerings, putting their customers first at every step of the process.'
      },
      GroupOfCompaniesData: {
        NameLocation: 'GroupOfCompanies'
      },
      BoardOfDirectorsData: {
        NameLocation: 'BoardOfDirectors'
      },
      OurMobileAppsData: {
        NameLocation: 'OurMobileApps'
      },
      ContactUsData: [
        {
          NameLocation: 'ContactUs',
          items: [
            'Client Service',
            'Finance',
            'Resolutions',
            'Procurement',
            'Medical Desk'
          ]
        }
      ],
      KeepingYouSafeItems: [
        {
          id: 0,
          directionClass: 'product--left',
          image: 'roadside-assist.png',
          icon: 'icon-roadside-and-accident-assist',
          heading: 'Roadside and Accident Assist',
          contentText:
            'Roadside assistance, tow-ins, courtesy transport, hotel accommodation, rentals and more.',
          active: false,
          sortOrder: 1
        },
        {
          id: 1,
          directionClass: 'product--right',
          image: 'emergency-medical.png',
          icon: 'icon-emergency-medical-services',
          heading: 'Emergency Medical Services',
          contentText:
            'From medical advice and information hotline to medical transportation.',
          active: true,
          sortOrder: 2
        },
        {
          id: 2,
          directionClass: 'product--left',
          image: 'intelligent-panic.png',
          icon: 'icon-intelligent-panic',
          heading: 'Intelligent Panic',
          contentText:
            "A panic service, incorporating a call centre linked to one's cell phone panic facility.",
          active: true,
          sortOrder: 3
        },
        {
          id: 3,
          directionClass: 'product--right',
          image: 'home-assist.png',
          icon: 'icon-home-assist',
          heading: 'Home Assist',
          contentText:
            'Assisting with fixtures, fittings, srvices, emergency services notification and call-out.',
          active: true,
          sortOrder: 4
        },
        {
          id: 4,
          directionClass: 'product--left',
          image: 'crime-victim-assist.png',
          icon: 'icon-crime-victim-assist',
          heading: 'Crime Victim Assist',
          contentText:
            '24-hour crisis management to assist you in the event of a hi-jacking or home invasion.',
          active: true,
          sortOrder: 5
        },
        {
          id: 5,
          directionClass: 'product--right',
          image: 'commercial-assist.png',
          icon: 'icon-commercial-assist',
          heading: 'Commercial Assist',
          contentText:
            'Consisting of various packages, vehicles under 3.5 ton and over 3.5 ton.',
          active: true,
          sortOrder: 6
        },
        {
          id: 6,
          directionClass: 'product--left',
          image: 'agri-assist.png',
          icon: 'icon-agri-assist',
          heading: 'Agri Assist',
          contentText:
            'Roadside, accident, panic, medical, trauma and security assistance for agricultural areas.',
          active: true,
          sortOrder: 7
        },
        {
          id: 7,
          directionClass: 'product--right',
          image: 'hiv-assist.png',
          icon: 'icon-hiv-assist',
          heading: 'HIV Assist',
          contentText:
            'Protection treatment service ensures confidential testing and treatment.',
          active: true,
          sortOrder: 8
        },
        {
          id: 8,
          directionClass: 'product--left',
          image: 'trauma-and-assault.png',
          icon: 'icon-trauma-assault-assist',
          heading: 'Trauma & Assault Assist',
          contentText:
            'We provide the member with counselling by trained medical professionals.',
          active: true,
          sortOrder: 9
        },
        {
          id: 9,
          directionClass: 'product--right',
          image: 'legal-assist.png',
          icon: 'icon-legal-assist',
          heading: 'Legal Assist',
          contentText:
            'We provide you with on-going access to a 24-hour legal advisory service.',
          active: true,
          sortOrder: 10
        },
        {
          id: 10,
          directionClass: 'product--left',
          image: 'home-safe-chauffeur.png',
          icon: 'icon-home-safe-assist',
          heading: 'Home Safe Chauffeur',
          contentText:
            'Assisting you and your vehicle to your destination and ensuring you are not a risk on the roads.',
          active: true,
          sortOrder: 11
        },
        {
          id: 11,
          directionClass: 'product--right',
          image: '24-Hour-Assist.png',
          icon: 'icon-roadside-and-accident-assist',
          heading: '24Hour Assist',
          contentText: 'Global Choices\' 24 Hour Emergency Motor Vehicle Accident Assist.',
          active: true,
          sortOrder: 11,
          url: 'https://24hourassist.co.za',
          siteName: 'https://24hourassist.co.za'
        }
      ],
      contactDepartments: [
        {
          id: 0,
          name: 'Client Service',
          email: 'clientservicejhb@globalchoices.co.za'
        },
        {
          id: 1,
          name: 'Finance',
          email: 'finance@globalchoices.co.za'
        },
        {
          id: 2,
          name: 'Resolutions',
          email: 'resolutions@globalchoices.co.za'
        },
        {
          id: 3,
          name: 'Procurement',
          email: 'procurement2@globalchoices.co.za'
        },
        {
          id: 4,
          name: 'Medical Desk',
          email: 'medicalDesk@globalchoices.co.za'
        }
      ],
      items: [
        'Client Service',
        'Finance',
        'Resolutions',
        'Procurement',
        'Medical Desk'
      ]
    }
  }
}
</script>

<style lang="scss"></style>
<!-- <script>
import HelloWorld from '../components/HelloWorld'

export default {
  name: 'HomeVue',

  components: {
    HelloWorld
  }
}
</script> -->
