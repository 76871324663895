<template>
    <div class="scroll-container" v-show="activeState" :class="{'hide-mobi_':hideOnMobile}">
        <div class="element-box" :style="'background-color:'+color">
            <div class="element" :class="{'--animate':animated}"  :style="'background-color:'+color"></div>

        </div>
        <p class="scroll-txt" :style="'color:'+color" v-show='scrollTxt'>SCROLL</p>
    </div>

</template>
<script>
export default {
  name: 'scrollerVuw',
  props: ['color', 'animated', 'activeState', 'scrollTxt', 'hideOnMobile']
}
</script>
<style scoped lang="scss">
.scroll-container{
    height:180px;
    width:150px;
  /*   //background-color:grey; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
  }
  .scroll-container p{
    margin-top:120px;
  }
  .element-box{
    width:150px;
    height:1px;
   transform: rotate(90deg);
    /* background-color: #fff; */
  }

  .element {
    height: 11px;
    width: 11px;
    /* background-color: #fff; */
    transform: translate(150px, 0);
    border-radius: 50%;
   margin-top:-5px

  }
  .element.--animate{
    /* animation: nudge 1.2s linear  ; */
    /* animation: nudge 4s linear infinite ; */
    animation: nudge 4s linear infinite ;
    /* animation-iteration-count: 2; */
  }

  @keyframes pulse {
    0%, 100% {
      background-color: red;
    }
    50% {
      background-color: orange;
    }
  }

  @keyframes nudge {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(150px, 0);
    }
    100% {
      transform: translate(150px, 0);
    }
  }

@media screen and (max-width: 480px) {
  .scroll-container.hide-mobi{
    display:none !important;
  }
}
</style>
