<template>
    <section class="footer">
        <div class="cookie-alert" >
            <v-alert
                :value="cookiealert"
                color="primary"
                dark
                border="left"
                prominent
            >
                 <v-row
                    align="center"
                    no-gutters
                >
                    <v-col class="grow">
                        We use “cookies” on our website which enable us to improve your future visits to our site as well as provide you with a more user-friendly experience.
                        Find out more in our <a href="./privacy-policy.pdf" target="_blank"><u>Privacy Policy</u></a>.
                    </v-col>
                    <v-col class="shrink">
                        <!-- <v-btn @click="cookiealert = !cookiealert">Accept</v-btn> -->
                        <v-btn  v-on:click="setCookieAccFlag">Accept</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </div>
        <footer>
            <div class="footer--social-links">
                <SocialIconsMobile />
            </div>

            <div class="footer--site-links">
                <ul>
                    <li><a href="./global-choices-paia-manual.pdf" target="_blank">PAIA Manual</a></li>
                    <li>|</li>
                    <li><a href="./PAIA-Form02-Reg7-Request-for-Access-to-Record.pdf" target="_blank">PAIA Request for Access to Record</a></li>
                    <li>|</li>
                    <li><a href="./Form-3-PAIA-Reg8-Outcome-of-Request-and-of-Fees-Payable.pdf" target="_blank">PAIA Outcome of Request</a></li>
                    <li>|</li>
                    <li><a href="./global-choices-popi-policy.pdf" target="_blank">POPI Policy</a></li>
                    <li>|</li>
                    <li><a href="./global-choices-privacy-policy.pdf" target="_blank">Privacy Policy</a></li>
                    <li>|</li>
                    <li><a href="./Global_Choices_Complaints_Management_Framework.pdf" target="_blank">Complaints Management Framework</a></li>
                    <li>|</li>
                    <li><a href="https://24hourassist.co.za" target="_blank" rel="noopener noreferrer">24hourassist.co.za</a></li>
                </ul>
            </div>
            <a href="/"><div class="footer--comp-logo"></div></a>
        </footer>
    </section>
</template>
<script>
import SocialIconsMobile from '@/components/ui/SocialIconsMobile'
import VueCookies from 'vue-cookies'
export default {
  name: 'FooterLayout',
  components: { SocialIconsMobile },
  props: [],
  data () {
    return {
      SetCookieAlert: false,
      cookiealert: true
    }
  },
  methods: {
    setCookieAccFlag () {
      VueCookies.set('accCookiePolicy', 'true', '1d')
      this.cookiealert = !this.cookiealert
      // VueCookies.set('username' , 'Paul', "1d");
      // Vue.$cookies.set('accCookiePolicy','true');
      // this.$cookies.get("token");/
    }
  },
  created: function () {
    const cookieAlertTracked = VueCookies.get('accCookiePolicy')
    if (cookieAlertTracked) {
      this.cookiealert = !this.cookiealert
    }

    // set variable
    // check if cookie exists
    // if cookie is null, cokoiealert stays true : show popup
    // if cookies is !null, set cookiealer to false: dont show popup

    //    let getPolicyCookie = this.$cookies.get("accCookiePolicy");
    //    let getPolicyCookie = VueCookies.get('accCookiePolicy');

    //    if (getPolicyCookie){
    //        console.log("it logs")
    //    }
  }
}
</script>
<style>

</style>
