<template>
    <section :id="NameLocation"
             class="landingv2 section"
             v-bind:class="this.LandingIntroData.BackgroundClass"
             data-themeColor="#fff"
             data-sectionThemeDark="true">
        <div class="fullscreen-video-wrap" v-show="this.LandingIntroData.ShowVideo">
            <video id="intro" autoplay loop muted>
                <source src="../../assets/video/gc-landing-page-720p.mp4"
                        type="video/mp4" />
            </video>

            <div class="header-overlay"></div>
        </div>

        <div class="comp-logo">
            <router-link to="/home">
                <img width="150px" src="../../assets/imgs/icons/global-choices-logo-white.png" />
            </router-link>
        </div>

        <div class="intro-heading --prim">
            <h1>Adding to Life by focusing<br> on what matters most.</h1>
            <p class="lead-text --prim">
                <br>"Empathy is the key to unlocking client insights<br>
                pro-actively, targeting their needs with<br>
                game-changing products"
                <br>
            </p>
            <p class="--quote-title"><b>Wimpie van der Merwe</b></p>
        </div>
        <a v-show="this.LandingIntroData.BtnState" href="#KeepingYouSafe" class="ihc__btn">
            <div class="btn intro-btn">how we add value</div>
        </a>
        <div class="scroller--element">
            <scroller v-bind="ScrollerConfig" />
        </div>
        <SocialIconsMobile v-bind="SocialIconsConfig" />
        <div class="copyright-text">
            Global Choices Lifestyle (Pty) Ltd is an authorised financial services
            provider FSP #44544<br />
            Copyright &copy; 2018 Global Choices All rights reserved.
        </div>
    </section>
</template>
<script>
import Scroller from '@/components/ui/Scroller.vue'
import SocialIconsMobile from '@/components/ui/SocialIconsMobile.vue'

export default {
  name: 'LandingIntroV2',
  components: { Scroller, SocialIconsMobile },
  props: ['LandingIntroData'],
  data () {
    return {
      ScrollerConfig: {
        color: '#fff',
        animated: true,
        activeState: true,
        scrollTxt: true,
        hideOnMobile: false
      },
      SocialIconsConfig: {
        DarkIconsTrue: false
      },
      NameLocation: 'Home',
      BackgroundClass: '--home-banner',
      HeadingText: 'Adding to Life by<br> focussing on what<br> matters most.',
      Btn: '#keepingYouSafe',
      BtnState: true
    }
  }
}
</script>
<style></style>
