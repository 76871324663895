<template>
    <section v-bind:id="NameLocation" class="contact-us section" data-sectionThemeDark="false">
        <div class="contact-us-content--container">

            <div class="section-sub-heading" style="grid-column: 1 / 3;">
                <h2 class="section-heading --blue">Contact Us</h2>
                In the event of emergency, please make use of your dedicated emergency assist line. <br />If you don’t have this number, please phone your Broker for assistance.<br />
                <div class="contact-number"><img src="../../assets/imgs/phone-number.svg">  0860 300 303</div>
            </div>

    <div class="form-container  ">

        <!--<p class="lead-text">Department Contacts:</p>
        <p>
            For client service queries contact <a href="mailto:clientservice@globalchoices.co.za">clientservice@globalchoices.co.za</a><br />
            For finance queries contact <a href="mailto:finance@globalchoices.co.za">finance@globalchoices.co.za</a><br />
            For resolutions queries contact <a href="mailto:resolutions@globalchoices.co.za">resolutions@globalchoices.co.za</a><br />
            For procurement queries contact <a href="mailto:procurement2@globalchoices.co.za">procurement2@globalchoices.co.za</a><br />
            For medical queries contact <a href="mailto:medicaldesk@globalchoices.co.za">medicaldesk@globalchoices.co.za</a>
        </p>-->

        <v-form @submit="formSubmit" ref="contactform"
                v-model="valid"
                :lazy-validation="lazy">

            <v-text-field v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required></v-text-field>

            <v-text-field v-model="email"
                          label="Email"
                          :rules="emailRules"
                          required></v-text-field>
            <v-select v-model="department"
                      :items="items"
                      label="Department"
                      :rules="[v => !!v || 'Department is required']"
                      required></v-select>
            <v-textarea name="Message"
                        label="Message"
                        value=""
                        :rules="messageRules"
                        required
                        v-model="message"></v-textarea>
            <button class="btn frm-submit">Send</button>

            <p v-bind:class="this.outputclass">
                {{output}}
            </p>

            <!--<v-btn :disabled="!valid"
                   color="success"
                   class="mr-4"
                   @click="validate">
                Validate
            </v-btn>

            <v-btn color="error"
                   class="mr-4"
                   @click="reset">
                Reset Form
            </v-btn>

            <v-btn color="warning"
                   @click="resetValidation">
                Reset Validation
            </v-btn>-->
        </v-form>
        <!--<strong>Output:</strong>-->
        <!--<br /><br />
        <span id="siteseal">
            <script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=vg5XsVyQKX7cqJtMlrHCFd3X2729zWwYt0k9pu0mDllLEmGtFyRHop03WXbo"></script>
        </span>-->

    </div>

    <!-- <div class="contact-details"> -->
    <div class="contact-details-info">
        <table class="tbl-contact">
            <tr>
                <td><img src="../../assets/imgs/map.svg"></td>
                <td>
                    <!-- Block G, Ground floor<br>
                    Metropolitan Office Park<br>
                    82 Wessel Road<br>
                    Rivonia, 2128 -->
                    Block C Ground Floor<br>
                    204 Rivonia Rd<br>
                    Morningside, Sandton, 2057<br>
                    South Africa
                </td>
            </tr>
            <!--<tr>
                <td><img src="../../assets/imgs/phone-number.svg"></td>
                <td>0860 300 303</td>
            </tr>-->
        </table>
    </div>
    <div class="contact-details-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.6167816461866!2d28.06145411554404!3d-26.078777683493943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95731da3497543%3A0x8b2b18cc4aa895c7!2sBlock%20C%20Ground%20Floor%2C%20204%20Rivonia%20Rd%2C%20Morningside%2C%20Sandton%2C%202057!5e0!3m2!1sen!2sza!4v1617196057328!5m2!1sen!2sza" style="border:0;" allowfullscreen="" class="mapiframe" loading="lazy"></iframe>

        <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.815687516249!2d28.06240195011981!3d-26.039585983430456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573cb2642198d%3A0xb142eb61f4f25a18!2sGlobal+Choices!5e0!3m2!1sen!2sza!4v1558959017785!5m2!1sen!2sza" frameborder="0" allowfullscreen class="mapiframe"></iframe>-->
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.815687516249!2d28.06240195011981!3d-26.039585983430456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573cb2642198d%3A0xb142eb61f4f25a18!2sGlobal+Choices!5e0!3m2!1sen!2sza!4v1558959017785!5m2!1sen!2sza" width="100%" height="265" frameborder="0" style="border:0" allowfullscreen class="mapiframe"></iframe> -->
    </div>
    <!-- </div> -->
    </div>
    </section>
</template>
<script>

export default {
  name: 'ContactUs',
  props: ['NameLocation', 'items'],
  mounted () {
    // console.log('Component mounted.')
  },
  data () {
    return {
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      department: null,
      message: '',
      messageRules: [
        v => !!v || 'Message is required'
      ],
      output: '',
      outputclass: '',
      lazy: false
    }
  },
  methods: {
    formSubmit (e) {
      e.preventDefault()
      const currentObj = this
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        }
      }
      if (currentObj.$refs.contactform.validate()) {
        this.axios.post('/Email', {
          name: this.name,
          email: this.email,
          department: this.department,
          message: this.message
        }, axiosConfig)
          .then(function () {
            // currentObj.output = "Thank you. Your message has been sent to the relevant department.";
            currentObj.output = 'Message sent successfully! '
            currentObj.outputclass = 'lead-text msg-success'
            currentObj.$refs.contactform.reset()
          })
          .catch(function (error) {
            currentObj.output = error
            currentObj.outputclass = 'lead-text msg-error'
          })
      }
      // this.$refs.contactform.reset()
    },
    validate () {
      if (this.$refs.contactform.validate()) {
        this.snackbar = true
      }
    },
    reset () {
      this.$refs.contactform.reset()
    },
    resetValidation () {
      this.$refs.contactform.resetValidation()
    }
  }
}

</script>
<!--<style>
    .v-text-field input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}
</style>-->
