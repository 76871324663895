<template>
    <div class="menu-container">
        <nav id="navbar" class="nav-bar" v-bind:class="[{'active':activated},{'nav-bar--light':this.sectionThemeDark},{' nav-bar--dark':!this.sectionThemeDark}]"    >
            <div class="menu icon  " v-bind:class="[{' icon-back-button':activated},{' icon-burger-menu':!activated}]"  v-on:click="markActive"></div>
            <ul class="nav-items">
                <!-- <a data-scroll href="#home">Anchor Link</a> -->
                <li :key="MenuItem.linkID" v-for="MenuItem in MenuItems">
                    <!-- <router-link :to="'#${MenuItem.linkNameLocation}'" :class="{'active':MenuItem.isCurrentSection}"> -->
                    <!-- <router-link :to="MenuItem.linkNameLocation" :class="{'active':MenuItem.isCurrentSection}" v-show="MenuItem.linkActive">
                        {{ MenuItem.linkName }}
                    </router-link> -->
                    <!-- <a :href="MenuItem.linkNameLocation" :class="{'active':MenuItem.isCurrentSection}">{{ MenuItem.linkName }}</a> -->
                    <!-- <a :href="{MenuItems.linkNameLocation}"
                    :class="{'active':MenuItem.isCurrentSection}"
                    >{{ MenuItem.linkName }}</a> -->
                    <a @click="goto(MenuItem.linkNameLocation)"
                    :class="{'active':MenuItem.isCurrentSection}"
                    >{{ MenuItem.linkName }}</a>
                </li>
                <!-- <li>
                      <button @click="goto('Home')" class="--sndry">Go to london</button>
                </li> -->
            </ul>
        </nav>
        <div class="close-menu" v-on:click="closeMenu"></div>
        <div  id="socialIconsWeb" class="social-icons-web">
            <ul class="social-items"
            v-bind:class="[
            {'light':this.socialIconsWebThemeDark},
            {'dark':!this.socialIconsWebThemeDark}
            ]">
                <li><a href="https://www.facebook.com/GlobalChoicesZA/" class="facebook" target="_blank" rel="noopener noreferrer"></a></li>
                <li><a href="https://twitter.com/GlobalChoicesZA" class="twitter"  target="_blank" rel="noopener noreferrer"></a></li>
                <li><a href="https://www.linkedin.com/company/global-choices-lifestyle/?originalSubdomain=za" class="linkedin"  target="_blank" rel="noopener noreferrer"></a></li>
                <li><a href="https://www.instagram.com/globalchoicesza/" class="instagram"  target="_blank" rel="noopener noreferrer"></a></li>
                <li><a href="https://www.youtube.com/channel/UCbCW_LVHs3zhXqtY6yRETBQ" class="youtube"  target="_blank" rel="noopener noreferrer"></a></li>
            </ul>
        </div>
    </div>
</template>

<script scoped>

export default {
  name: 'ResponsiveBurgerMenu',
  // props:["menuConfig","menuClass","sectionThemeDark","backgroundColor","scrolled","currentSectionID","scrollBarPos","scrollBarPosYoffset","sectionHeight","sectionEndPos"],
  props: ['MenuItems'],
  data () {
    return {
      activated: false,
      menuClass: 'nav-bar--light',
      sectionThemeDark: true,
      backgroundColor: '#fff',
      scrolled: false,
      currentSectionID: 0,
      scrollBarPos: 0,
      scrollBarPosYoffset: 0,
      sectionHeight: 0,
      sectionEndPos: 0,
      socialIconsWebThemeDark: true,
      currentMenuItem: ''
    }
  },
  methods: {
    markActive () {
      this.activated = !this.activated
      // handles the opening and closing of the menu
    },
    closeMenu () {
      this.activated = !this.activated
    },
    handleScroll () {
      let scrolling = false
      // function scrollCheck() {
      //    scrolling = true;
      // }
      setInterval(function () {
        if (scrolling) {
          scrolling = false
        }
      }, 250)

      this.scrollBarPos = window.scrollY
      // console.log("scrolled"+this.scrolled);
      // console.log("scrollBarPos"+this.scrollBarPos);

      let letCurrentSectionID = 0
      let letNextSectionID = 0
      let letMenuSectionID = 0
      // let myNewMenuClass = this.backgroundColor;
      const windowHeight = window.innerHeight
      const iconTriggerPosition = windowHeight * 0.9
      // let sectionMenuTriggerPosition = windowHeight*0.3;
      // console.log("~~iconTriggerPosition"+iconTriggerPosition);

      // console.log("~~windowHeight"+windowHeight);
      const sections = document.querySelectorAll('.section')
      this.scrollBarPosYoffset = window.pageYOffset

      let index = 0; const length = sections.length
      for (index; index < length; index++) {
        // set co-ordinates
        const sectionStartPos = sections[index].offsetTop
        // let sectionHeight = sections[index].offsetHeight;
        // let sectionEndPos = sectionStartPos + sectionHeight;

        if ((sectionStartPos - 30) <= this.scrollBarPos) {
          this.currentSectionID = index
          letCurrentSectionID = index
          // could possibly use more if statements here to trigger methods and change colours of seperate components.
        }
        if ((sectionStartPos - iconTriggerPosition) <= this.scrollBarPos) {
          // this.currentSectionID = index;
          letNextSectionID = index

          // could possibly use more if statements here to trigger methods and change colours of seperate components.
          // console.log("letNextSectionID");
          // console.log("+++"+sectionStartPos);
          //  console.log("$$$iconsText"+iconTriggerPosition);
        }
        if ((sectionStartPos - 30) <= this.scrollBarPos) {
          // this.currentSectionID = index;
          letMenuSectionID = index

          // could possibly use more if statements here to trigger methods and change colours of seperate components.
          // console.log("letMenuSectionID");

          //  console.log("sectionStartPos: "+sectionStartPos + " | this.scrollBarPos "+ this.scrollBarPos);
          //  console.log("sectionMenuTriggerPosition: "+sectionMenuTriggerPosition + " | sectionStartPos-sectionMenuTriggerPosition = ");
          //  console.log(sectionStartPos-sectionMenuTriggerPosition);
        }
      }
      // console.log("currentSectionID: "+letCurrentSectionID+" sections: "+sections.length);
      //  setMenuClass(currentSectionID,sections);

      this.setMenuClass(letCurrentSectionID, sections)
      // this.setMenuItemState(letCurrentSectionID,sections);
      this.setMenuItemState(letMenuSectionID)
      this.setSocialIconClass(letNextSectionID, sections)
    },
    setMenuClass (sectionID, sections) {
      // et nav = document.querySelector('#socialIconsWeb ul.social-items');
      // let themeColor = "";
      let ValSectionThemeDark = ''
      // themeColor = sections[sectionID].dataset.themecolor;  // get the themeColor of the data-set attribute
      ValSectionThemeDark = sections[sectionID].dataset.sectionthemedark // get the themeColor of the data-set attribute

      //   console.log("*** sectionID: "+sectionID+" ValSectionThemeDark: "+ValSectionThemeDark);

      if (!ValSectionThemeDark) {
        // console.log("ValSectionThemeDark:"+ValSectionThemeDark);
        // if no value, make menu default white.
        this.sectionThemeDark = true
      } else if (ValSectionThemeDark === 'true') {
        // console.log("ValSectionThemeDark:"+ValSectionThemeDark);
        this.sectionThemeDark = true
        // console.log("ThisSectionThemeDark-true:"+this.sectionThemeDark);
      } else {
        this.sectionThemeDark = false
        // console.log("ThisSectionThemeDark-false:"+this.sectionThemeDark);
      }
    },
    setSocialIconClass (nextSectionID, sections) {
      // let nav = document.querySelector('#navbar');
      // let themeColor = "";
      let ValSocialIconsWebThemeDark = ''
      // themeColor = sections[sectionID].dataset.themecolor;  // get the themeColor of the data-set attribute
      ValSocialIconsWebThemeDark = sections[nextSectionID].dataset.sectionthemedark // get the themeColor of the data-set attribute

      // console.log("___ nextSectionID: "+nextSectionID+" ValSocialIconsWebThemeDark: "+ValSocialIconsWebThemeDark);

      if (!ValSocialIconsWebThemeDark) {
        // console.log("ValSocialIconsWebThemeDark:"+ValSocialIconsWebThemeDark);
        // if no value, make menu default white.
        this.socialIconsWebThemeDark = true
      } else if (ValSocialIconsWebThemeDark === 'true') {
        // console.log("ValSocialIconsWebThemeDark:"+ValSocialIconsWebThemeDark);
        this.socialIconsWebThemeDark = true
        // console.log("ThisSectionThemeDark-true:"+this.sectionThemeDark);
      } else {
        this.socialIconsWebThemeDark = false
        // console.log("ThisSectionThemeDark-false:"+this.sectionThemeDark);
      }
    },
    setMenuItemState (sectionID) {
      // let SectionActiveState = "";
      let currentSectionName = ''
      currentSectionName = this.MenuItems[sectionID].linkNameLocation
      // SectionActiveState = sections[sectionID];
      // console.log(this.MenuItems[sectionID].isCurrentSection);

      //  menuactive = sections[sectionID].dataset.menuactive;
      // console.log(" this sectionID: "+ sectionID +" SectionActiveState "+ menuactive);

      this.currentMenuItem = currentSectionName

      // if(this.currentMenuItem === currentSectionName){
      //     console.log('current section is '+ this.currentMenuItem );

      // }
      // console.log("what is this SectionActiveState: "+ SectionActiveState);
      //   console.log("what is currentSectionName: "+ currentSectionName);

      // TODO : try to console log the contact us section ID from the selector index.
      //   console.log("what is currentSectionID: "+ currentSectionName);

      //   console.log("*** sectionID: "+sectionID+" SectionActiveState: "+SectionActiveState);
      // trying to make somethin glike
      // If current section = current section, set status to true
      // in vue. can bind a class to only show if the class is true. so that should sort itself out automaticaly if i can set the stats in MenuItems array on Home.vue
      // if(this.MenuItems[sectionID] = this.MenuItems[sectionID]){
      //     // this.MenuItems[sectionID].isCurrentSection = true;
      //     this.MenuItems.isCurrentSection = true;
      // }

      const useMenuItems = this.MenuItems; let indexMenu = 0; const length = useMenuItems.length
      for (indexMenu; indexMenu < length; indexMenu++) {
        const currentMenuItemsNameLoc = useMenuItems[indexMenu].linkNameLocation
        // let currentMenuItemsID = useMenuItems[indexMenu].linkID;
        // console.log('MenuItems.linkNameLocation : '+useMenuItems[indexMenu].linkNameLocation + ' | MenuItems.linkID : '+useMenuItems[indexMenu].linkID);
        if (currentMenuItemsNameLoc === currentSectionName) {
          // console.log("~~ Current Section is: "+ currentSectionName + " set active state = True");
          useMenuItems[indexMenu].isCurrentSection = true
        } else {
          // console.log("~~  Sections Dont match set active state = False");
          useMenuItems[indexMenu].isCurrentSection = false
        }
      }
    },
    goto (idName) {
      const navLocation = document.getElementById(idName)
      const top = navLocation.offsetTop
      window.scrollTo(0, top)
    }
  },
  created: function () {
    let scrolling = false
    // attached listner to scrollActive, make scrolling true
    window.addEventListener('scroll', scrollActive)
    function scrollActive () {
      scrolling = true
    }

    /* ** dont remove **
         on created. this continuesly goes off every 0.35sec, checks if the page is scrolling.
        if it is, trigger the handleScroll method.         */
    setInterval(() => {
      if (scrolling) {
        scrolling = false
        this.handleScroll()
      }
    }, 350)
  }
}
</script>

<style lang="scss" scoped>

</style>
