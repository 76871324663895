<template>
    <section  v-bind:id="NameLocation" class="executive-summary section" data-themeColor="#4157a2"  data-sectionThemeDark="false">
        <div class="summary-container">
            <h2 class="section-heading" v-bind:class="TitleClass" v-html="Title"> </h2>
            <p class="lead-text" v-show="LeadText" v-html="LeadText"></p>
            <p class="content-text" v-html="Content"></p>
        </div>
        <div class="scroller--element">
            <scroller v-bind="ScrollerConfig" />
        </div>
        <SocialIconsMobile v-bind="SocialIconsMobileSettings" />
    </section>
</template>
<script>
import Scroller from '@/components/ui/Scroller'
import SocialIconsMobile from '@/components/ui/SocialIconsMobile'
export default {
  name: 'ExecutiveSummary',
  components: { Scroller, SocialIconsMobile },
  props: ['NameLocation', 'Title', 'TitleClass', 'LeadText', 'Content'],
  data () {
    return {
      ScrollerConfig: {
        color: '#c6c6c6',
        animated: true,
        activeState: true,
        scrollTxt: false
      },
      SocialIconsMobileSettings: {
        DarkIconsTrue: true
      }
    }
  }
}
</script>
<style>

</style>
