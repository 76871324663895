<template>
    <section v-bind:id="NameLocation" class="group-of-companies section --group-of-companies" data-sectionThemeDark="true">
        <div class="summary-container" style="grid-column: 2/3;">
            <h2 class="section-heading">Group of Companies</h2>
            <p class="lead-text">We have partnered with Digital Path and Claim Central Africa to create shared experiences that are enhanced simultaneously across multiple devices, platforms and touchpoints.</p>
            <div class="companys-icons">
              <router-link to="/digitalpath"><div class="digital-path"></div></router-link>
              <router-link to="/claimcentral"><div class="claim-central"></div></router-link>
            </div>
            <SocialIconsMobile />
        </div>
        <!-- <h2 class="section-heading">Group of Companies</h2>
        <p class="lead-text">We have partnered with Digital Path and Claim Central Africa to create shared experiences that are enhanced simultaneously across multiple devices, platforms and touchpoints.</p> -->

        <div class="scroller--element">
            <scroller v-bind="ScrollerConfig" />
        </div>
        <!-- <SocialIconsMobile /> -->
    </section>
</template>
<script>
import Scroller from '@/components/ui/Scroller'
import SocialIconsMobile from '@/components/ui/SocialIconsMobile'

export default {
  name: 'GroupOfCompanies',
  components: { Scroller, SocialIconsMobile },
  props: ['NameLocation'],
  data () {
    return {
      ScrollerConfig: {
        color: '#c6c6c6',
        animated: true,
        activeState: true,
        scrollTxt: false
      }
    }
  }
}
</script>
<style>

</style>
