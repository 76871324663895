var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-icons-mobile"},[_c('ul',{staticClass:"social-items",class:[
        {'dark':_vm.DarkIconsTrue},
        {'light':!_vm.DarkIconsTrue}
        ]},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"facebook",attrs:{"href":"https://www.facebook.com/GlobalChoicesZA/","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"twitter",attrs:{"href":"https://twitter.com/GlobalChoicesZA","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"linkedin",attrs:{"href":"https://www.linkedin.com/company/global-choices-lifestyle/?originalSubdomain=za","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"instagram",attrs:{"href":"https://www.instagram.com/globalchoicesza/","target":"_blank","rel":"noopener noreferrer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"youtube",attrs:{"href":"https://www.youtube.com/channel/UCbCW_LVHs3zhXqtY6yRETBQ","target":"_blank","rel":"noopener noreferrer"}})])
}]

export { render, staticRenderFns }